import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_7/subSlide';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-7-1-8"}}) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_7/mobile/slides-bg/8.jpg"}) {
        childImageSharp {
            fixed(width: 1920) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
      }
    }
  `);

  return (
    <Template query={query} />
  );
};


export default Slide;
